import React from 'react'

import Loader from '../shared/Loader/Loader';

import './ScreenLoading.css'

const ScreenLoading = () => {
  // const [state, setState] = useState(null);

  return (
    <div className="screen ScreenLoading">
      <Loader />
    </div>
  );
};

export default ScreenLoading;
