import { observer } from 'mobx-react-lite';
import React from 'react';
import Texts from 'react-sdk/Texts';
import AppState, { SCREENS } from '../../../js/AppState';
import './Header.css';
// TODO ce n'est plus le même vertxApi !!
const Header = ({children}) => {

  function handleLogout() {
    AppState.vertxApi.logout()
    .then(() => AppState.vertxApi.anonymousAutoconnect())
  }

  return (
    <div className="Header">
      <h1>{Texts.get("app_title")}</h1>

      {children}
      <div className="spacer" />


      <div className="language-selector">
        <select value={AppState.language} onChange={e => AppState.setLanguage(e.target.value)}>
          {window.CONFIG.languages.map(l =>
          <option value={l} key={l}>{l}</option>)}
        </select>
      </div>
      {AppState.vertxApi.isAnonymous && <span>Connecté en anonymous</span>}

      {AppState.vertxApi.currentUser && !AppState.vertxApi.isAnonymous &&
      <div className="user">
        {AppState.vertxApi.currentUser.Pseudo || AppState.vertxApi.currentUser.Login}
        <button onClick={handleLogout}>{Texts.get("user-logout")}</button>
      </div>}

      {(!AppState.vertxApi.currentUser || AppState.vertxApi.isAnonymous) &&
      <div className="user">
        <button onClick={() => AppState.setScreen(SCREENS.LOGIN)}>{Texts.get("user-login")}</button>
      </div>}
    </div>
  );
};

export default  observer(Header)
