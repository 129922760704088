import { makeAutoObservable } from "mobx";
import DataLoading from 'react-sdk/DataLoading';
import Texts from "react-sdk/Texts";

import Notifications from 'react-sdk/Notifications/Notifications'
import LP_api from "./LP_api";
import { getUrlVar } from "react-sdk/utils";
import ScaleManager from "react-sdk/ScaleManager";




export const SCREENS = {
  LOADING : "LOADING",
  HOME : "HOME",
  LOGIN : "LOGIN",
  GAME : "GAME",
  GAME_END: "GAME_END"
}

export const POPUPS = {
  MENTION: "MENTION",
  TRAILER: "TRAILER",
  SETTINGS: "SETTINGS",
  VIDEO: "VIDEO"
}


class AppState {
  debugMode = false
  language = "fr"
  screen = SCREENS.LOADING
  popup = null

  onFullscreen = false

  gameSettings = {}

  iframeKey = 0


  constructor() {
    this.debugMode = getUrlVar("debug")
    this.ctfMode = getUrlVar("ctf") !== null

    ScaleManager.init(1920, 1080)

    if ('virtualKeyboard' in navigator) navigator.virtualKeyboard.overlaysContent = true


    let vertxconfig = window.VertxConfig.createFromObjet(window.CONFIG.vertx)
    this.vertxApi = window.VertxApi.create(vertxconfig)

    this.lp_api = new LP_api()

    makeAutoObservable(this, { vertxApi: false })

    DataLoading.getAllFiles(window.CONFIG.requiredFiles, window.CONFIG.root)
    .then(files => {
      Texts.init(files.texts, this.language)
    })
    .then(() => {
      return new Promise( (resolve) => {
        this.vertxApi.user.autoConnect((res, data) => {
          if(!res.status) {
            Notifications.error("Erreur de connexion au serveur (" + res.statusMessage + ")", 3000)
          }
          resolve()

          this.vertxApi.analytics.score("ctf_mode", this.ctfMode ? 1 : 0)

        })
      })
    })
    .then(() => this.setScreen(SCREENS.HOME))
  }

  setScreen(id) {
    this.vertxApi.analytics.navigationLeaveAndEnter("screen." + this.screen, "screen." + id)
    this.screen = id
  }

  setPopup(popup_id) {
    if(popup_id) {
      this.vertxApi.analytics.navigationEnter("popup." + popup_id)
    }
    else {
      this.vertxApi.analytics.navigationLeave("popup." + this.popup)
    }
    this.popup = popup_id
  }

  setLanguage(l) {
    this.language = l
    Texts.setLanguage(l)
  }

  toggleFullscreen() {
    if(this.onFullscreen){
      this.deactivateFullscreen()
    }
    else {
      this.activateFullscreen()
    }
  }

  activateFullscreen() {
    var isFullscreenAvailable = document.fullscreenEnabled;
    if(!isFullscreenAvailable) {
      console.log("fullscreen not authorized")
      return
    }
    window.document.documentElement.requestFullscreen()
    this.onFullscreen = true
  }

  deactivateFullscreen() {
    var isFullscreenAvailable = document.fullscreenEnabled;
    if(!isFullscreenAvailable) {
      console.log("fullscreen not authorized")
      return
    }
    window.document.exitFullscreen()
    this.onFullscreen = false
  }

  refreshIframe() {
    this.iframeKey++
  }

}


export default new AppState()
