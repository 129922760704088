import AppState, { POPUPS, SCREENS } from "./AppState"

export default class LP_api {

  args = null

  getVars() {
    if(this.args.vars) return this.args.vars
    return {}
  }
  openSettings(vars, onClose, onChange) {
    this.args = {vars, onClose, onChange}
    AppState.setPopup(POPUPS.SETTINGS)
  }

  endGame(vars){
    this.args = {vars}

    AppState.setScreen(SCREENS.GAME_END)
  }
  getVertxApi(){
    return AppState.vertxApi
  }

  toggleFullscreen() {
    AppState.toggleFullscreen()
  }

  openVideo(id, onClose) {
    this.args = {id, onClose}
    AppState.setPopup(POPUPS.VIDEO)
  }

  registerVirtualKeyboardEvent(callback) {
    if ('virtualKeyboard' in navigator) navigator.virtualKeyboard.addEventListener('geometrychange', callback)
  }




}