import { observer } from 'mobx-react-lite';
import Footer from '../shared/Footer/Footer';

import './ScreenHome.css';

import { isMobile } from 'mobile-device-detect';
import Texts from 'react-sdk/Texts';
import logo_ope_kernel from '../../images/Logo_operationkernel.png';
import AppState, { SCREENS } from '../../js/AppState';



const ScreenHome = () => {

  function handlePlayClick() {
    if(window.CONFIG.fullscreen === 1 && isMobile) AppState.activateFullscreen()
    if(window.CONFIG.fullscreen === 2 ) AppState.activateFullscreen()


    AppState.setScreen(SCREENS.GAME)
  }

  return (
    <div className="ScreenHome">

      <div className="spacer" />
      <div className="logo">
        <img src={logo_ope_kernel} alt="logo-operation-kernel-cos" title='Opération Kernel 3.0'/>
      </div>

      <div className="spacer" />

      <h1>{Texts.get("main-title")}</h1>

      <div className="spacer" />

      <button className='play-button' onClick={handlePlayClick}>{Texts.get("play-button")}</button>

      <div className="spacer" />

      <div className="bottom">
        <h2 dangerouslySetInnerHTML={{__html: Texts.get("bottom-title")}} />
        <p dangerouslySetInnerHTML={{__html: Texts.get("bottom-text")}} />

        <a href={Texts.get("ctf-button-url")} target="_blank">
          <button>{Texts.get("ctf-button")}</button>
        </a>
      </div>

      <Footer />



    </div>
  );
};

export default  observer(ScreenHome)
