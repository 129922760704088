import React, { useEffect, useState } from 'react'
import Texts from 'react-sdk/Texts';
import AppState from '../../js/AppState';

import './PopupVideo.css'

const PopupVideo = () => {

  const [showSkipButton, setshowSkipButton] = useState(false);

  useEffect(() => {
    if(showSkipButton) {
      let timer = setTimeout(() => setshowSkipButton(false), 1500)

      return () => clearTimeout(timer)
    }
  }, [showSkipButton]);

  function handleClose() {
    AppState.setPopup(null)
    if(AppState.lp_api.args.onClose) AppState.lp_api.args.onClose()
  }


  let video_url = Texts.get(AppState.lp_api.args.id)

  let mutedSettings = AppState.gameSettings && AppState.gameSettings.isMuted

  let muted = mutedSettings !== undefined ? mutedSettings : false


  return (
    <div className="PopupVideo" onClick={() => setshowSkipButton(true)}>
      <video muted={muted} playsInline autoPlay src={video_url} onEnded={handleClose}></video>

      {showSkipButton && <button onClick={handleClose}>{Texts.get("video-skip")}</button>}

    </div>
  );
};

export default PopupVideo;
