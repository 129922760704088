import React from 'react'
import Texts from 'react-sdk/Texts';
import AppState from '../../js/AppState';
import Popup from '../shared/Popup/Popup';

import './PopupMentionsLegales.css'

const PopupMentionsLegales = () => {

  return (
    <Popup className="PopupMentionsLegales" title={Texts.get("mentions-popup-title")} onClose={() => AppState.setPopup(null)}>
      <div dangerouslySetInnerHTML={{__html: Texts.get("mentions-popup-content")}} />

    </Popup>
  );
};

export default PopupMentionsLegales;
