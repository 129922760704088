import React, { useEffect } from 'react'

import './EndPopup.css'
import game_logo from "../../images/Logo_operationkernel.png"

import AppState, { SCREENS } from '../../js/AppState';
import Texts from 'react-sdk/Texts';
import { secsToMinSecs } from 'react-sdk/utils';

import logo_cos from '../../images/Logo_Cos.png'
import logo_succubus_line from '../../images/Succubus_Logo_Line_Vertical.png'

const EndPopup = () => {
  let vars = AppState.lp_api.getVars()
  let {win, time_secs, ending} = vars

  useEffect(() => {
    AppState.vertxApi.analytics.contentShow("gameend." + ending)
    return () => {
      AppState.vertxApi.analytics.contentHide("gameend." + ending)
    }

  }, []);

  function handleRetourMenuClick(e) {
    e.preventDefault()
    AppState.setPopup(null)
    AppState.setScreen(SCREENS.HOME)
    AppState.refreshIframe()
  }


  if(win) {
    return (
      <div className="endgame-ctnr">
        <div className='EndPopup win'>
          <div className="absolute-logos">
            <img src={logo_cos} alt="" />
            <img src={logo_succubus_line} alt="" />
          </div>


          <div className="EndPopup-logo">
            <img src={game_logo} alt="logo-operation-kernel" />
          </div>

          <h3 className="Popup-title">{Texts.get("win-popup-title")}</h3>

          {!AppState.ctfMode &&
          <div className="EndPopup-game-datas">
          <div className="game-data">
            <div className="game-data-title">{Texts.get("end-popup-time")}</div>
            <div className="game-data-value">{secsToMinSecs(time_secs)}</div>
          </div>
        </div>}


          <p className="EndPopup-text">{Texts.get(ending)}</p>

          {!AppState.ctfMode &&
          <div className="EndPopup-game-datas lost-button">
            <a href={Texts.get("end-popup-en-savoir-plus-target")} target="_blank">
              <button>{Texts.get("end-popup-en-savoir-plus")}</button>
            </a>
            <div><a className='EndPopup-link' href='#' onClick={handleRetourMenuClick}>{Texts.get("end-popup-retour-menu")}</a></div>
          </div>}

          {AppState.ctfMode &&
          <div className="EndPopup-game-datas">
            <div className="game-data">
              <div className="game-data-title">{Texts.get("end-popup-time")}</div>
              <div className="game-data-value">{secsToMinSecs(time_secs)}</div>
            </div>
            <div className="game-data">
              <div className="game-data-title">{Texts.get("win-popup-password-title")}</div>
              <div className="game-data-value">{Texts.get("win-popup-password-text")}</div>
            </div>


            <a href={Texts.get("end-popup-ctf-continue-target")} target="_blank">
              <button>{Texts.get("end-popup-ctf-continue")}</button>
            </a>
          </div>}

          {AppState.ctfMode &&
          <div>
            <a className='EndPopup-link' target="_blank" href={Texts.get("end-popup-en-savoir-plus-target")}>
              {Texts.get("end-popup-en-savoir-plus")}
            </a>
          </div>}
        </div>
      </div>
    )

  }
  else {
    return (
      <div className="endgame-ctnr">
        <div className='EndPopup lost'>
          <div className="EndPopup-logo">
            <img src={game_logo} alt="logo-operation-kernel" />
          </div>
          <h3 className="Popup-title">{Texts.get("lost-popup-title")}</h3>

          <div className="EndPopup-game-datas">
            <div className="game-data">
              <div className="game-data-title">{Texts.get("end-popup-time")}</div>
              <div className="game-data-value">{secsToMinSecs(time_secs)}</div>
            </div>
          </div>

          <p className="EndPopup-text ftol" >{Texts.get(ending)}</p>

          <div className="EndPopup-game-datas lost-button">
              {/* pour un lien qui ressemble à un bouton */}
              <a href={Texts.get("end-popup-en-savoir-plus-target")} target="_blank">
                <button className='white'>{Texts.get("end-popup-en-savoir-plus")}</button>
              </a>
            <div><a className='EndPopup-link' href='#' onClick={handleRetourMenuClick}>{Texts.get("end-popup-retour-menu")}</a></div>
          </div>
        </div>
      </div>
    )

  }
};

export default EndPopup;
