import { v4 as uuidv4 } from 'uuid';

export const pickRandom = arr => arr[Math.floor( Math.random() * arr.length )]
export const arrayIntersection = (arr1, arr2) => arr1.filter(x => arr2.includes(x))

export const arrayDifference = (arr1, arr2) => arr1.filter(x => arr2.indexOf(x) === -1)
export const arrayRemoveDuplicates = (arr) => arr.filter((x, index) => arr.indexOf(x) === index)

export const randomRange = (min, max) => Math.random() * (max - min) + min

export const Round = (val, precision = 0) => {
  if(val !== undefined) {
    let coef = Math.pow(10, precision)
    val = Math.round((val + Number.EPSILON) * coef) / coef
    val = val.toFixed(precision)
  }
  return val
}

export const makeId = () => uuidv4()
export const secsToMinSecs = s => (s-(s%=60))/60+(9<s?':':':0')+s


export const getUrlVar = (name) => {
  var url = new URL(window.location.href);
  return url.searchParams.get(name);
}