import React from 'react'
import Texts from 'react-sdk/Texts';

import './Footer.css'
import succubus_logo from "../../../images/succubus-footer-logo.png"
import capgemini_logo from "../../../images/Capgemini_Logo.png"
import AppState, { POPUPS } from '../../../js/AppState';

const Footer = () => {


  function handleClick(e, popup_id) {
    e.preventDefault()
    AppState.setPopup(popup_id)
  }


  return (
    <div className="Footer">
      <div className="baseline">
        <img src={succubus_logo} alt="logo-succcubus" />
        <div>Seriously made with fun by <a href="https://www.succubus.fr" target='_blank'>succubus interactive</a></div>
      </div>

      <div className="menu-bottom">
        {/* <a onClick={e => handleClick(e, POPUPS.APROPOS)} href="#">{Texts.get("footer-apropos")}</a> */}
        <a onClick={e => handleClick(e, POPUPS.MENTION)} href="#">{Texts.get("footer-mentions")}</a>
        {/* <a onClick={e => handleClick(e, POPUPS.CONTACT)} href="#">{Texts.get("footer-contact")}</a> */}
      </div>

      <div className="baseline">
        {/* <div dangerouslySetInnerHTML={{__html: Texts.get("capgemini-footer-baseline")}} /> */}
        <a target='_blank' href={Texts.get("capgemini-footer-link")}>
          <img className='capgemini-logo' src={capgemini_logo} alt="logo-capgemini" />
        </a>
      </div>

    </div>
  );
};

export default Footer;
