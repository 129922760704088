import React from 'react'
import Popup from '../shared/Popup/Popup';

import './TrailerPopup.css'

import YouTube from 'react-youtube';
import Texts from 'react-sdk/Texts';
import AppState from '../../js/AppState';
import { useEffect } from 'react';

const youtubeConfig = {
  autoplay: 1,
  controls: 1,
  cc_load_policy: 0,
  fs: 1,
  iv_load_policy: 3,
  modestbranding: 1,
  rel: 0,
  showinfo: 0,
}

const TrailerPopup = () => {

  const videoId = Texts.get("trailer-youtube-id")

  useEffect(() => {
    AppState.vertxApi.analytics.contentShow("trailer_id." + videoId)

    return () => {
      AppState.vertxApi.analytics.contentHide("trailer_id." + videoId)

    }
  }, []);

  return (
    <Popup className="TrailerPopup" onClose={() => AppState.setPopup(null)}>
      <YouTube videoId={videoId} opts={{ height: '390', width: '640', playerVars: youtubeConfig}} />
    </Popup>
  );
};

export default TrailerPopup;
