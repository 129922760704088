import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import AppState, { POPUPS, SCREENS } from "../../js/AppState";

import ScreenLoading from "../ScreenLoading/ScreenLoading";
import ScreenHome from "../ScreenHome/ScreenHome";

import ScaleManager from 'react-sdk/ScaleManager'

import './App.css'
import '../../fonts/stylesheet.css'
import ScreenLogin from "../ScreenLogin/ScreenLogin";
import PopupMentionsLegales from "../PopupMentionsLegales/PopupMentionsLegales";

import ScreenGame from "../ScreenGame/ScreenGame";
import EndPopup from "../EndPopup/EndPopup";
import TrailerPopup from "../TrailerPopup/TrailerPopup";
import SettingsPopup from "../SettingsPopup/SettingsPopup";

import Notifications from "../shared/Notifications/Notifications";
import PopupVideo from "../PopupVideo/PopupVideo";
const App = () => {



  return (
    <>
      <div className="App"  style={ScaleManager.style}>

        {AppState.screen === SCREENS.LOADING && <ScreenLoading />}
        {AppState.screen === SCREENS.HOME && <ScreenHome />}
        {AppState.screen === SCREENS.LOGIN && <ScreenLogin />}
        {AppState.screen === SCREENS.GAME_END && <EndPopup />}

        <Notifications />

        <div className="version">v{process.env.REACT_APP_VERSION}</div>
        {AppState.debugMode && <div className="debug">Debug mode: {AppState.debugMode}</div>}
      </div>

      <ScreenGame />

      {AppState.popup && <div className="App2"  style={ScaleManager.style}>
        {AppState.popup === POPUPS.MENTION && <PopupMentionsLegales />}
        {AppState.popup === POPUPS.SETTINGS && <SettingsPopup />}
        {AppState.popup === POPUPS.TRAILER && <TrailerPopup />}
        {AppState.popup === POPUPS.VIDEO && <PopupVideo />}
      </div>}

    </>
  )
}

export default observer(App);
