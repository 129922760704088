import React, { useRef, useState } from 'react';
import AppState from '../../../js/AppState';
// TODO ce n'est plus le même vertxApi !!
export default ({onDone}) => {
  const formRef = useRef(null)
  const [error, setError] = useState(null);

  function handleSubmit (e) {
    e.preventDefault()
    setError(null)

    const data = new FormData(formRef.current)

    const email = data.get('email')
    const password = data.get('password')
    const confirmPassword = data.get('confirm-password')
    const pseudo = data.get('pseudo')

    if(password !== confirmPassword) {
      setError("The 2 password fields don't match")
      return
    }

    AppState.vertxApi.userCreate(email, password, pseudo)
    .then(() => onDone() )
    .catch(err => {
      setError(err)
    })

  }

  return (
  <div className='LoginForm'>
    <h2>Create account</h2>
    <form onSubmit={handleSubmit} ref={formRef}>
      <div>
        <input name="email" id="email" type="email" placeholder="Mail" />
      </div>

      <div>
        <input name="pseudo" id="pseudo" type="text" placeholder="Pseudo" />
      </div>

      <div>
        <input name="password" id="password" type="password" placeholder="Password" />
      </div>

      <div>
        <input name="confirm-password" id="confirm-password" type="password" placeholder="Password confirm" />
      </div>
      {error && <div className="error">{error}</div>}
      <button>Submit</button>
    </form>


  </div>)
}